@import url("https://fonts.googleapis.com/css?family=Spectral|Caudex|Roboto+Condensed:400&display=swap");

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Spectral', 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url("../assets/bg.svg");
    background-attachment: fixed;
    background-position: center;
  }

  .navbar-brand {
    font-size: 1rem;
    font-weight: bolder;
  }
  
.desc {
    font-style: italic;
    font-size: 0.8em;
}

.date {
    font-style: italic;
    font-size: 0.8em;
}

.badge-lg {
    font-size: 1.1em;
}

.bg-dark{
    background-color: green !important;
}

.cb {
    font-size: 0.8em;
    font-style: italic;
    font-weight: bold;
}

.loader {
    min-height: calc(100vh - 45px);
    padding-top: calc(50vh - 45px);
}

.app-bottom-bar {
    li.nav-item {
        list-style: none;
        min-width: 20%;
        text-align: center;
    }
}

.login-form {
    margin-top: calc(50vh - 220px);
}

.btn-setup {
    color: #fff !important;
}

.page-party-list {
    .accordion-button.active {
        color: #fff !important;

        &:after {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
        }
    }
}

.page-invoice-list {
    .card {
        padding: 1.25rem 0.25rem;
        text-align: center;  
        color: #fff;
        font-size: 0.8rem;

        h4 {
            font-weight: bolder !important;  
        }
    }

    .badge-small {
        font-size: 0.6rem;
        margin-top: -10px;
    }

    .badge-notice {
        margin-top: -5px;
        margin-left: 5px;
        position: absolute;
    }

    .date-info {
        font-size: 0.8rem;
    }

    .accordion-button.active {
        color: #fff !important;

        &:after {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
        }
    }
}

.back-to-home {
    position: fixed;
    bottom: 60px;
    left: 20px;
    width: 40px;
    border-radius: 50px;
    text-align: center;
}

.page-header {
    color: #fff;
    padding: 1rem;
    padding-bottom: 100px;
    border-radius: 10px;

    h1, h2, h3 {
        font-weight: bolder;
    }
}

.page-items {
    margin: -50px 1rem 0;
}

.card-item {
    margin: 5px 0;
    border-width: 1px !important;
    border-radius: 6px;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -webkit-box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
    box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);

    h1, h2, h3 {
        font-weight: bolder;
        margin-bottom: 0;
    }
}

.version-number {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0.9rem;
}

.page-vouchers {
    .nav-item {
        margin-bottom: 20px;
        width: 25%;

        .nav-link {
            color: #000;
            cursor: pointer;

            &:hover {
                color: #000;
            }

            &.active {
                border-bottom: 2px solid #000;
                font-weight: bold;
            }
        }
    }

    .accordion-voucher-header{
        position: relative;
        width: 100%;

        .badge {
            float: right;
            margin-right: 10px;
            font-size: 1.1em;
        }
    }
}

.page-dashboard {
    .card {
        background-color: #fff !important;
        min-height: 200px;

        .cta {
            position: absolute;
            bottom: 15px;
            left: 15px;
            min-width: 150px;
        }
    }

    .balance.card 
    {
        margin: 10px 0;

        #rzp_payment_form {
            position: absolute;
            bottom: 15px;
            left: 15px;
            min-width: 150px;
        }

        .card-background {
            position: absolute;
            background: no-repeat;
            background-image: url(../assets/balance.png);
            background-position: right center;
            background-origin: content-box;
            background-size: contain;
            top: 0px;
            right: 10px;
            bottom: 0px;
            left: 0px;
            opacity: 0.25;
        }
    }

    .banking.card 
    {
        margin: 10px 0;

        .card-body {
            z-index: 100;
        }

        .card-background {
            position: absolute;
            background: no-repeat;
            background-image: url(../assets/bank.png);
            background-position: right center;
            background-origin: content-box;
            background-size: contain;
            top: 0px;
            right: 10px;
            bottom: 0px;
            left: 0px;
            opacity: 0.25;
        }
    }

    .contact.card 
    {
        margin: 10px 0;

        .card-body {
            z-index: 100;
        }

        .card-background {
            position: absolute;
            background: no-repeat;
            background-image: url(../assets/sales-person.png);
            background-position: right center;
            background-origin: content-box;
            background-size: contain;
            top: 0px;
            right: 10px;
            bottom: 0px;
            left: 0px;
            opacity: 0.25;
        }
    }

}

.page-business {
    .nav-item {
        margin-bottom: 20px;

        .nav-link {
            color: #000;
            cursor: pointer;

            &:hover {
                color: #000;
            }

            &.active {
                border-bottom: 2px solid #000;
            }
        }
    }
}

.btn-make-payment {
    position: absolute;
    bottom: 15px;
}


.footer {
    color: #fff;
    margin-left: -0.75rem;
    margin-right: -0.75rem;

    a {
        color: #fff;
        text-decoration: none; 
    }
}

.bg-image {    
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    margin-top: -10px;
}

.group-item {
    padding: 0.5rem 0;

    img {
        overflow-clip-margin: content-box;
        overflow: clip;
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .group-image {
        position: relative;
        overflow: hidden;
        max-height: 140px;
    }
    
    .group-name {
        font-size: 1.2rem;
        color: #111;
        font-weight: bold;
        font-family: Caudex, 'Segoe UI', "Times New Roman", sans-serif;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        margin: 10px 0;
    }

    @media only screen and (min-width: 900px) {
        .group-name {
            height: 90px;
            min-height: 90px;
        }
    }
}

@media only screen and (min-width: 900px) {
    .group-item {
        padding: 0.5rem;
    }
}

@media only screen and (max-width: 900px) {
    .group-card {
        padding: 0;
    }    
}

.page-product-list {
    .group-name {
        font-size: 2.5rem;
        font-weight: bolder;
        font-family: Caudex, 'Segoe UI', "Times New Roman", sans-serif;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        padding: 20px 0;
    }

    .card{
        padding: 1rem;
        margin-bottom: 20px;

        .card-image {
            text-align: center;

            img {
                height: 250px;
                object-fit: contain;
                max-width: 100%;
            }
        }

        .card-title {
            font-size: 1.5rem;
            margin-top: 20px;
            margin-bottom: 20px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
        }

        .card-text {
            margin-bottom: 20px;
        }

        @media only screen and (min-width: 900px) {
            .card-text {
                max-height: 300px;
                min-height: 300px;
                overflow-y: auto;
            }
        }
    }
}

.page-product-detail {
    .product-name {
        font-size: 2.5rem;
        font-weight: bolder;
        font-family: Caudex, 'Segoe UI', "Times New Roman", sans-serif;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        padding: 20px 0;
    }

    .table {
        font-weight: bold;
        font-size: 1.4rem;
    }

    .product-description {        
        padding: 20px 0;
    }
}

.page {
    background-color: #fff;

    .btn-back-to-home {
        position: fixed;
        bottom: 60px;
        right: 20px;
        text-align: center;
        color: #fff;
    }
}
